import { camelCase } from './str';

export const uniqueId = (() => {
  let i = 0;
  return prefix => {
    i += 1;
    return `${prefix ? `${prefix}_` : ''}${i}`;
  };
})();

export const addSuffixToId = (id, suffix) => `${id}-${suffix}`;

/**
 * Filters all keys in an object that do not start with a given prefix
 * and return a new object without the prefix
 */
export const removePrefixFromKeys = (obj, prefix) => {
  const unprefixedObj = {};
  Object.keys(obj)
    .filter(key => key.startsWith(prefix))
    .forEach((key) => {
      const uiKey = camelCase(key.slice(prefix.length));
      unprefixedObj[uiKey] = obj[key];
    });
  return unprefixedObj;
};

/**
 * iterates through an nested object trying to find the deepest object that does not have
 * the given propName and returns
 * @param  {object} obj
 * @param  {string} propName
 * @return {object}
 */
export const getDeepestProp = (obj, propName) => {
  let child = obj;
  let nextChild;
  while (nextChild = child[propName]) { // eslint-disable-line no-cond-assign
    child = nextChild;
  }
  return child;
};
