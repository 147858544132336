import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { extractDataAttributes } from '../../../helpers/misc';
import { ariaHtmlAttributes, ariaPropsTypes } from '../../../helpers/ariaAttributes';

function FormOptionBullet(props) {
  const inputProps = {
    id: props.id,
    name: props.name,
    value: props.value,
    checked: props.checked,
  };

  const states = [
    props.checked && 'selected',
    props.disabled && ' disabled',
  ];

  const modifiers = [
    props.filled && 'filled',
  ];

  const inputRef = useRef(null);
  const keyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ' || event.button === 0) {
      const data = {
        target: {
          id: props.id,
          name: props.name,
          value: props.value,
          checked: !props.checked,
        },
      };

      props.onChange(data);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const dataset = extractDataAttributes(props.data);

  return (
    <div
      className={suitcss({
        componentName: 'FormOptionBullet',
        states,
        modifiers,
      })}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseDown={keyDown}
      {...dataset}
    >
      <input
        ref={inputRef}
        className={suitcss({
          componentName: 'FormOptionBullet',
          descendantName: 'input',
        })}
        type="radio"
        {...inputProps}
        onKeyDown={keyDown}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        data-tracking="off"
        aria-label={props.label}
        {...ariaHtmlAttributes(props)}
      />

      <label
        className={suitcss({
          componentName: 'FormOptionBullet',
          descendantName: 'label',
        })}
        htmlFor={props.id}
      >
        <span
          className={suitcss({
            componentName: 'FormOptionBullet',
            descendantName: 'labelInner',
          })}
          style={{ backgroundColor: props.value }}
        />
      </label>
    </div>
  );
}

FormOptionBullet.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  filled: PropTypes.bool,
  data: PropTypes.object,
  ...ariaPropsTypes,
};

FormOptionBullet.defaultProps = {
  checked: false,
  disabled: false,
  filled: false,
};

export default FormOptionBullet;
