import PageModule from './PageModule';
import component from '../../containers/contentStage/ContentStageSlim';

class ContentStageSlimModule extends PageModule {
  constructor(moduleData) {
    super(component, moduleData);
  }
}

export default ContentStageSlimModule;
