import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import Link from '../text/TextLink';

const componentName = 'StageLinkButton';
const StageLinkButton = (props) => {
  const {
    label,
    ariaLabel,
    target,
    showBorder,
    highlightButton,
    borderClasses,
    buttonClasses,
  } = props;
  return (
    <div className={suitcss({
      componentName,
      modifiers: [!showBorder ? 'invisible' : ''],
      borderClasses,
      })}
    >
      <Link
        className={suitcss({
          componentName,
          descendantName: 'inner',
          buttonClasses,
        })}
        to={target}
        asButton
        buttonFilled
        withoutArrow
        highlight={highlightButton}
        {...(ariaLabel ? { ariaLabel } : {})}
      >
        {label}
      </Link>
    </div>
  );
};

StageLinkButton.propTypes = {
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  target: PropTypes.string,
  showBorder: PropTypes.bool,
  highlightButton: PropTypes.bool,
  borderClasses: PropTypes.string,
  buttonClasses: PropTypes.string,
};

export default StageLinkButton;
