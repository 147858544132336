import ApiResponse from '../../model/ApiResponse';
import ApiError from '../../model/errors/ApiError';
import { showAlert, showDialog } from '../page/dialog';
import { trackError, log } from '../tracking/event';
import {
  ERROR_DISPLAY_STYLE_DIALOG, ACTION_PREFIX,
} from '../../helpers/constants';
import ErrorLogEntry from '../../model/logging/ErrorLogEntry';
import Dialog from '../../model/Dialog';
import { makeAbstractError } from '../../helpers/misc';
import FetchError from '../../model/errors/FetchError';
import { sleep } from '../../helpers/polling';
import LoginRequest from '../../model/requests/LoginRequest';
import PageRequest from '../../model/requests/PageRequest';

export const REQUEST_RECEIVED_RESPONSE = `${ACTION_PREFIX}/REQUEST_RECEIVED_RESPONSE`;
export const REQUEST_RECEIVED_RESPONSE_META = `${ACTION_PREFIX}/REQUEST_RECEIVED_RESPONSE_META`;
export const REQUEST_RECEIVED_ERROR = `${ACTION_PREFIX}/REQUEST_RECEIVED_ERROR`;
export const REQUEST_IN_PROGRESS = `${ACTION_PREFIX}/REQUEST_IN_PROGRESS`;
export const REQUEST_HISTORY_REMOVE = `${ACTION_PREFIX}/REQUEST_HISTORY_REMOVE`;

/**
 * Removes a request from the request history.
 *
 * @param {QueueableRequest} request
 */
export const removeFromHistory = request => ({
  type: REQUEST_HISTORY_REMOVE,
  payload: {
    id: request.id,
  },
});

/**
 * Action to dispatch once a request has been successfuly resolved.
 *
 * @param {QueueableRequest} request
 * @param {Object} response
 * @param {Object} meta
 */
export const receivedMeta = (request, meta) => ({
  type: REQUEST_RECEIVED_RESPONSE_META,
  meta: {
    request,
  },
  payload: {
    meta,
  },
});

/**
 * Action to dispatch once a request has been successfuly resolved.
 *
 * @param {QueueableRequest} request
 * @param {Object} response
 */
export const receivedResponse = (request, response) => ({
  type: REQUEST_RECEIVED_RESPONSE,
  meta: {
    request,
    identifier: request.id, // used for loading bar
  },
  payload: { response },
});

/**
 * Action to dispatch in case a request failed.
 *
 * @param {QueueableRequest} request
 * @param {AbstractError} error
 */
export const receivedError = (request, error) => (dispatch, getState) => {
  const { site, ui } = getState();
  dispatch({
    type: REQUEST_RECEIVED_ERROR,
    meta: {
      request,
      identifier: request.id, // used for loading bar
    },
    payload: { error },
  });
  const isLoginRequest = request instanceof LoginRequest;
  const isPageRequest = request instanceof PageRequest;

  if (isLoginRequest || isPageRequest) {
    dispatch(
      trackError(
        true,
        isLoginRequest ? 'authentication' : 'technical',
        ui[`err${error.fullCode}`],
        error.fullCode,
      ),
    );
  }

  if (error.isSilent() || request.isPreventDefaultErrorHandling(error)) {
    return;
  }

  const { dialogTemplates = {} } = site; // note: dialogTemplates may not exist yet!
  const dialogData = Object
    .values(dialogTemplates || {})
    .find(d => d.errorCodes.includes(error.fullCode));

  let errorText = '';
  if (dialogData) { // show error as dialog
    const dialogEntity = dispatch(Dialog.createFromApiModel(dialogData));
    const { headline, copy } = dialogEntity;
    dispatch(showDialog(dialogEntity));
    dispatch(log(new ErrorLogEntry(error, copy, ERROR_DISPLAY_STYLE_DIALOG)));
    errorText = [headline, copy].toString();
  } else { // show error as toaster
    errorText = error.getVerbalization(ui);
    dispatch(showAlert(error));
  }
  dispatch(
    trackError(
      false,
      'notification',
      errorText,
      error.fullCode,
    ),
  );
};

/**
 * Signals the system that a request has been initiated
 *
 * @param {QueueableRequest} request
 */
export const requestInProgess = request => ({
  type: REQUEST_IN_PROGRESS,
  meta: {
    identifier: request.id, // used for loading bar
  },
  payload: { request },
});

/**
 * This is the place where the request is dispatched to the server.
 * It is a wrapper for the browser's native [fetch]{@link https://github.com/github/fetch}.
 *
 * @param {QueueableRequest} request
 * @return {Promise<ApiResponse>}
 * @throws ApiError|AbstractError
 */
export const fetch = (request, forwardErrorResponse = false) => async (dispatch, getState) => {
  request.increaseTries();
  const state = getState();
  let response = null;

  try {

    let rawResponse;
    const fakeResponse = await request.getFakeResponse(state);
    if (fakeResponse) {
      rawResponse = fakeResponse;
    } else {
      try {
        rawResponse = await global.fetch(request.url, {
          method: request.method.toUpperCase(), // needs upppercase; @see http://stackoverflow.com/q/34666680
          body: request.getSerializedPayload(state),
          headers: request.getHeaders(state),
          credentials: 'include', // accepting cookies from the server
        });
      } catch (error) {
        if (request.tries <= request.maxTries) {
          return await sleep(() => dispatch(fetch(request)), 250 * (2 ** request.tries));
        }
        throw new FetchError(request, error);
      }
    }
    response = await ApiResponse.createFromResponse(
      rawResponse,
      request.responseDataType,
      request.camelizeResponse,
    );

    // before we proceed with normalizing and returning the response,
    // we inform the system about the meta that was contained in the response
    // some event handlers may throw errors (e.g. on redirect meta data), which
    // will abort any subsequent processing
    await dispatch(receivedMeta(request, response.body.meta));

    if (!response.isSuccess) {
      throw new ApiError(response, request);
    }

    const normalizedResponse = await request.handleResponse(response, dispatch, getState);
    return normalizedResponse;

  } catch (e) {
    // extra safeguard to ensure we always return an instance of AbstractError,
    // even if our code crashed for some unknown reasons while processing the response.
    throw makeAbstractError(e);

  } finally {
    if (forwardErrorResponse) {
      return response; // eslint-disable-line
    }
  }
};

export const fetchWithErrors = request => async (dispatch) => {
  return dispatch(fetch(request, true));
};
