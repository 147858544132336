import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import suitcss from '../../../helpers/suitcss';
import StageLinkButton from '../../basics/button/StageLinkButton';
import connectUI from '../../basics/ui/UIConnector';

const componentName = 'ContentStageSlim';
const ContentStageSlim = (props) => {
  const prepareContainerStyling = (stage) => {
    const { backgroundColorValue, backgroundGradient } = stage;
    const setBackground = backgroundGradient
      ? { background: backgroundGradient }
      : { backgroundColor: backgroundColorValue || '#fff' };

    return {
      ...setBackground,
    };
  };

  const renderTextBox = (stage) => {
    const {
      headline,
      headlineMobile,
      subline,
      secondSubline,
      sublineMobile,
    } = stage;

    return (
      <div
        className={suitcss({
          componentName,
          descendantName: 'textBox',
        })}
      >
        <h1
          className={suitcss({
            componentName,
            descendantName: 'headline',
            modifiers: ['desktop'],
          })}
        >
          {headline}
        </h1>
        <h1
          className={suitcss({
            componentName,
            descendantName: 'headline',
            modifiers: ['mobile'],
          })}
        >
          {headlineMobile || headline}
        </h1>

        <p
          className={suitcss({
            componentName,
            descendantName: 'subline',
            modifiers: ['desktop'],
          })}
        >
          <span>{subline}</span>
          {secondSubline && (
            <span>{secondSubline}</span>
          )}
        </p>
        <p
          className={suitcss({
            componentName,
            descendantName: 'subline',
            modifiers: ['mobile'],
          })}
        >
          {sublineMobile || (
            <>
              <span>{subline}</span>
              {secondSubline && (
                <span>{secondSubline}</span>
              )}
            </>
          )}
        </p>
      </div>
    );
  };

  const renderStageImage = (stage) => {
    const { desktopImage, mobileImage } = stage;
    return (
      <>
        <img
          className={suitcss({
            componentName,
            descendantName: 'image',
            modifiers: ['desktop'],
          })}
          src={desktopImage.src}
          alt={desktopImage.alt || ''}
        />
        <img
          className={suitcss({
            componentName,
            descendantName: 'image',
            modifiers: ['mobile'],
          })}
          src={mobileImage ? mobileImage.src : desktopImage.src}
          alt={mobileImage ? (mobileImage.alt || '') : (desktopImage.alt || '')}
        />
      </>
    );
  };

  const handleClick = (url) => {
    const { dispatch } = props;
    dispatch(push(url));
  };

  const renderContentStage = (stage, index) => (
    <div
      className={suitcss({
        componentName,
        descendantName: 'item',
        modifiers: [stage.toggleAlternativeTextColor ? 'textLight' : 'textDefault'],
      })}
      style={prepareContainerStyling(stage)}
      key={`contentStage-item-${index}`}
      onClick={() => handleClick(stage.button.url)}
    >
      {renderTextBox(stage)}
      <div
        className={suitcss({
          componentName,
          descendantName: 'imageContainer',
        })}
      >
        {renderStageImage(stage)}
        <StageLinkButton
          label={stage.button.title || 'Test'}
          target={stage.button.url}
          showBorder
          highlightButton={stage.toggleAlternativeButtonStyle}
          ariaLabel={stage.buttonAriaLabel}
        />
      </div>
    </div>
  );

  const { params: { stages } } = props;
  if (!stages) return null;
  const isSplitContentStage = stages.length > 1;
  return (
    <>
      <section
        className={suitcss({
          componentName,
          modifiers: [isSplitContentStage ? 'splitStage' : ''],
        })}
      >
        {stages.map((stage, index) => renderContentStage(stage, index))}
      </section>
    </>
  );
};

ContentStageSlim.propTypes = {
  params: PropTypes.shape({
    stages: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string.isRequired,
        headlineMobile: PropTypes.string,
        subline: PropTypes.string.isRequired,
        secondSubline: PropTypes.string,
        sublineMobile: PropTypes.string,
        toggleAlternativeTextColor: PropTypes.bool,
        desktopImage: PropTypes.object.isRequired,
        mobileImage: PropTypes.object.isRequired,
        backgroundColorValue: PropTypes.string.isRequired,
        backgroundGradient: PropTypes.string,
        button: PropTypes.object.isRequired,
        buttonAriaLabel: PropTypes.string,
        toggleAlternativeButtonStyle: PropTypes.bool,
      }),
    ),
  }),
  dispatch: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({ dispatch });
export default compose(
  connect(mapDispatchToProps),
  connectUI(),
)(ContentStageSlim);
