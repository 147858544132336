import React from 'react';
import PropTypes from 'prop-types';

import Unit from '../../basics/text/TextUnit';
import Copy from '../../basics/text/TextCopy';

import suitcss from '../../../helpers/suitcss';

const componentName = 'ProcessHeaderPrice';
function ProcessHeaderPrice({
  singlePaymentFee,
  singlePaymentFeeStrike,
  paymentFee,
  paymentFeeStrike,
  ui,
  modifiers,
  utilities,
  shippingFeeHint,
}) {
  const unitModifiers = ['default', 'inline'].concat(modifiers);
  return (
    <dl className={suitcss({ componentName, modifiers, utilities })}>
      <dt className={suitcss({ componentName, descendantName: 'key' })}>
        <Copy size="tertiary" element={'div'} embedded>
          {ui.singlePaymentFee}
        </Copy>
      </dt>
      <dd className={suitcss({ componentName, descendantName: 'value' })}>
        <Unit
          prefix={ui.singlePaymentPrefix}
          modifiers={unitModifiers}
          price={singlePaymentFee}
          prefixPrice={singlePaymentFeeStrike}
        />
      </dd>
      <dt className={suitcss({ componentName, descendantName: 'key' })}>
        <Copy size="tertiary" element={'div'} embedded>
          {ui.paymentFee}
        </Copy>
      </dt>
      <dd className={suitcss({ componentName, descendantName: 'value' })}>
        <Unit
          modifiers={unitModifiers}
          price={paymentFee}
          prefixPrice={paymentFeeStrike}
        />
      </dd>
      {shippingFeeHint &&
      <Copy className={suitcss({
        componentName,
        descendantName: 'shipping',
        utilities: ['colorGray100'],
      })}
      >
        {ui.shippingHint}
      </Copy>
      }
    </dl>
  );
}

ProcessHeaderPrice.propTypes = {
  singlePaymentFee: PropTypes.object.isRequired,
  paymentFee: PropTypes.object.isRequired,
  ui: PropTypes.shape({
    singlePaymentPrefix: PropTypes.string,
    singlePaymentFee: PropTypes.string.isRequired,
    paymentFee: PropTypes.string.isRequired,
  }),
  modifiers: PropTypes.array,
  utilities: PropTypes.array,
  singlePaymentFeeStrike: PropTypes.object,
  paymentFeeStrike: PropTypes.object,
  shippingFeeHint: PropTypes.bool,
};

ProcessHeaderPrice.defaultProps = {
  modifiers: [],
};

export default ProcessHeaderPrice;
