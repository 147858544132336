import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { trackClick } from '../../actions/tracking/event';
import {
  MYTRACK_FAQ_TOPIC,
  MYTRACK_FAQ_DETAIL,
  TRACK_REPLACE_ANSWER_ID,
  TRACK_REPLACE_TOPIC_ID,
} from '../../helpers/constants';

import ContentStageSlimComposition from '../../components/compositions/contentStage/ContentStageSlim';

function ContentStageSlim(props) {
  const { params } = props;
  return (
    <ContentStageSlimComposition params={params} />
  );
}
ContentStageSlim.propTypes = {
  params: PropTypes.object.isRequired,
  locationHash: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  trackTopic: PropTypes.func.isRequired,
  trackDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { location }) => ({
  locationHash: location ? decodeURI(location.hash).replace('#', '') : '',
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: hash =>
    dispatch(push(`${ownProps.location.pathname}${hash ? `#${hash}` : ''}`)),
  trackTopic: topicId => {
    dispatch(trackClick({
      link_position: 'content-stage-slim module',
      link_title: MYTRACK_FAQ_TOPIC.replace(TRACK_REPLACE_TOPIC_ID, topicId),
      link_type: 'button',
    }));
  },
  trackDetail: answerId => {
    dispatch(trackClick({
      link_position: 'content-stage-slim module',
      link_title: MYTRACK_FAQ_DETAIL.replace(TRACK_REPLACE_ANSWER_ID, answerId),
      link_type: 'button',
    }));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ContentStageSlim);
